import { db } from "../firebase/firebase-config";
import { agendarTurno, startLoading } from "../actions/turnos";

const mesesEnEspanol = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const getHorarios = async (
  diaSeleccionado,
  sethorarios,
  companySelected
) => {
  let mes = diaSeleccionado.getMonth();
  const nameMonth = mesesEnEspanol[mes];

  const dbRef = await db
    .collection(`branches`)
    .doc(
      `${
        companySelected === "Auta"
          ? "Auta"
          : companySelected === "Lavallol"
          ? "lavallol"
          : "wM8khZV4K3M8wtzVoWw3"
      }`
    )
    .collection(`appointmentType`)
    .doc(`inspection`)
    .collection(`2024`)
    .doc(`${nameMonth}`)
    .collection(`days`)
    .doc(`${diaSeleccionado.getUTCDate()}`)
    .collection(`times`)
    .get();

  let horarios = [];
  let availableHorarios = [];

  dbRef.docs.forEach((dbData) => {
    horarios.push(dbData.id);
    availableHorarios.push(dbData.data());
  });

  let array = [];

  availableHorarios.forEach((info) => {
    array.push(info);
  });

  for (var j = 0; j < horarios.length; j++) {
    array[j].horario = horarios[j];
  }

  sethorarios(array);
};

export const getDiasMes = async (
  setDaysDisabledOneMonth,
  setDaysDisabledTwoMonth,
  setallDaysDisabled,
  companySelected
) => {
  const fechaActual = new Date();
  const getMonth = fechaActual.getMonth();

  const nameOneMonth = mesesEnEspanol[getMonth];
  const nameTwoMonth = mesesEnEspanol[(getMonth + 1) % 12];

  //One Month
  const dbRefEnero = await db
    .collection(`branches`)
    .doc(
      `${
        companySelected === "Auta"
          ? "Auta"
          : companySelected === "Lavallol"
          ? "lavallol"
          : "wM8khZV4K3M8wtzVoWw3"
      }`
    )
    .collection(`appointmentType`)
    .doc(`inspection`)
    .collection(`2024`)
    .doc(`${nameOneMonth}`)
    .collection(`days`)
    .get();

  let dataDbDiaOneMonth = [];
  let dataDbAvailableOneMonth = [];

  dbRefEnero.docs.forEach((dbData) => {
    dataDbDiaOneMonth.push(dbData.id);
    dataDbAvailableOneMonth.push(dbData.data());
  });

  let arrayOneMonth = [];
  dataDbAvailableOneMonth.forEach((info) => {
    arrayOneMonth.push(info);
  });

  for (var j = 0; j < dataDbDiaOneMonth.length; j++) {
    arrayOneMonth[j].dia = Number(dataDbDiaOneMonth[j]);
    arrayOneMonth[j].mes = nameOneMonth.toLocaleLowerCase();
    arrayOneMonth[j].numberMonth = getMonth;
  }

  const arrayOrderOneMonth = arrayOneMonth.sort((o1, o2) => {
    if (o1.dia < o2.dia) {
      return -1;
    } else if (o1.dia > o2.dia) {
      return 1;
    } else {
      return 0;
    }
  });

  const diasDisabledOneMonthArray = arrayOrderOneMonth.filter(
    (x) => x.available === false
  );

  const mapMarzo = diasDisabledOneMonthArray.map((info) => info.dia);
  setDaysDisabledOneMonth(mapMarzo);

  //TwoMonth
  const dbRef = await db
    .collection(`branches`)
    .doc(
      `${
        companySelected === "Auta"
          ? "Auta"
          : companySelected === "Lavallol"
          ? "lavallol"
          : "wM8khZV4K3M8wtzVoWw3"
      }`
    )
    .collection(`appointmentType`)
    .doc(`inspection`)
    .collection(`2024`)
    .doc(`${nameTwoMonth}`)
    .collection(`days`)
    .get();

  let dataDbDiaTwoMonth = [];
  let dataDbAvailableTwoMonth = [];

  dbRef.docs.forEach((dbData) => {
    dataDbDiaTwoMonth.push(dbData.id);
    dataDbAvailableTwoMonth.push(dbData.data());
  });

  let array = [];
  dataDbAvailableTwoMonth.forEach((info) => {
    array.push(info);
  });

  for (var j = 0; j < dataDbDiaTwoMonth.length; j++) {
    array[j].dia = Number(dataDbDiaTwoMonth[j]);
    array[j].mes = nameTwoMonth.toLocaleLowerCase();
    array[j].numberMonth = getMonth + 1;
  }

  const arrayOrderTwoMonth = array.sort((o1, o2) => {
    if (o1.dia < o2.dia) {
      return -1;
    } else if (o1.dia > o2.dia) {
      return 1;
    } else {
      return 0;
    }
  });

  const diasDisabledTwoMonth = arrayOrderTwoMonth.filter(
    (x) => x.available === false
  );

  const mapAbril = diasDisabledTwoMonth.map((info) => info.dia);
  setDaysDisabledTwoMonth(mapAbril);

  const array3 = diasDisabledTwoMonth.concat(diasDisabledOneMonthArray);
  setallDaysDisabled(array3);
};

export const agendar = (
  diaSeleccionado,
  horarioSeleccionado,
  valuePatente,
  deal_id,
  navigate,
  email,
  dispatch,
  setmodal,
  type,
  sucursal,
  paracaidas
) => {
  const año = diaSeleccionado.getFullYear();
  const dia = diaSeleccionado.getUTCDate();
  let mes = diaSeleccionado.getMonth();
  const nameMonth = mesesEnEspanol[mes];

  setmodal(true);
  dispatch(startLoading(true, ""));
  agendarTurno(
    año,
    nameMonth,
    dia,
    horarioSeleccionado,
    deal_id,
    navigate,
    email,
    valuePatente,
    dispatch,
    type,
    sucursal,
    paracaidas
  );
};
