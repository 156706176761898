import React from "react";
import "./style.css";
import { colors } from "../../styles/colors";

const HoursComponent = ({
  activeHorario,
  horarios,
  seleccionarHorarios,
  diaSeleccionado,
  habilitarTodosLosHorarios, // Nuevo prop para habilitar todos los horarios
}) => {
  const currentTime = new Date();
  const currentDate = currentTime.toLocaleDateString();
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  // Función que verifica si el horario ya ha pasado (solo si es el día de hoy)
  const isPastHorario = (horario) => {
    const [hours, minutes] = horario.split(":").map(Number);
    if (hours < currentHours) return true;
    if (hours === currentHours && minutes < currentMinutes) return true;
    return false;
  };

  return (
    <>
      <div className={activeHorario ? "container-hours" : "displayNone"}>
        <div className="subcontainer-hours">
          {activeHorario && horarios !== undefined
            ? horarios.map((info, i) => {
                const isToday =
                  diaSeleccionado?.toLocaleDateString() === currentDate;
                const isPast = isToday ? isPastHorario(info.horario) : false;

                // Si el checkbox está marcado, ignoramos `info.available` y `isPast`
                const isAvailable =
                  habilitarTodosLosHorarios || (info.available && !isPast);

                return (
                  <div key={i}>
                    <div
                      className="container-buttons-option-hours"
                      style={{
                        border: `1px solid ${
                          isAvailable
                            ? colors.primary.primary
                            : colors.purple.lightPurple
                        }`,
                        color: `${
                          isAvailable ? "#FFF" : colors.purple.lightPurple
                        }`,
                        cursor: `${isAvailable ? "pointer" : "auto"}`,
                        backgroundColor: `${
                          isAvailable ? colors.primary.primary : "#FFF"
                        }`,
                      }}
                      onClick={() =>
                        isAvailable ? seleccionarHorarios(info.horario) : null
                      }
                    >
                      {info.horario}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};

export default HoursComponent;
