import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import axios from "axios";
import Swal from "sweetalert2";

export const agendarTurno = async (
  año,
  mes,
  dia,
  horarioSeleccionado,
  deal_id,
  navigate,
  email,
  patente,
  dispatch,
  type,
  sucursal,
  paracaidas
) => {
  const data = {
    year: String(año),
    month: String(mes),
    day: String(dia),
    time: horarioSeleccionado,
    dealId: deal_id,
    type: type,
    agent: email,
    patente: patente,
    sucursal: sucursal,
    source: "auta",
    parachuter: paracaidas,
  };

  const dbRef = await db.collection("deals").doc(`${deal_id}`).get();

  const { car } = dbRef.data();

  if (Object.keys(car).length === 0) {
    Swal.fire({
      title: "No tenés un auto para que cotizemos.",
      icon: "info",
      text: "Por favor, clickea en el botón para cargar tu auto.",
      confirmButtonColor: "#29a1dc",
      confirmButtonText: `<a href=https://deal.chevroletsanjorge.com.ar/cargar/${deal_id} style="text-decoration:none; color:white; font-size:15px">Cargar</a>`,
    });
  } else {
    let response = axios
      .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
      .then((resp) => {
        dispatch(startLoading(false, resp.status));
        if (type === "pretoma") {
          navigate(`/resumen-toma/${deal_id}`);
        } else {
          navigate(`/resumen-inspeccion/${deal_id}`);
        }
        Swal.fire("Agendado", "Turno agendado correctamente.", "success");
      })
      .catch((e) => {
        console.log(e);
        dispatch(startLoading(false, e.request.status));
      });
  }
};

export const cancelarTurnoDb = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "inspeccion",
    agent: email,
    source: "auta",
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Cancelado", "Turno cancelado correctamente.", "success");
      dispatch(sendPutTurnos("cancelar"));
      navigate(`/inspeccion/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const modificarTurnoDb = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "inspeccion",
    agent: email,
    source: "auta",
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Realizado", "Ya podés editar tu turno", "success");
      dispatch(sendPutTurnos("modificar"));
      navigate(`/inspeccion/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const sendPutTurnos = (data) => ({
  type: types.PutTurnos,
  payload: {
    data,
  },
});

export const startLoading = (loading, status) => ({
  type: types.Loading,
  payload: {
    loading,
    status,
  },
});
